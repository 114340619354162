<template>
    <transition name="slide-up">
        <div class="content-container content-half" v-if="isActive">
            <v-form ref="form">
                <div class="flex-column">
                    <div class="d-flex">
                        <!--<div class="question-number">1-</div>-->
                        <div class="flex-column padding-div w-100 mb-3">
                            <div class="fs-32px fw-700 color-dark">
                                Qual é o e-mail do seu dependente? 
                            </div>
                            <div class="single-input">
                                <v-text-field placeholder="Digite sua resposta aqui" required :rules="[rules.required, rules.email]" @paste="disablePaste" @copy="disableCopy" v-model="email" @keyup.native.enter="next" v-on:keyup.enter="next" />

                            </div>
                        </div>
                    </div>
                    <div class="d-flex">
                        <!--<div class="question-number color-white">1-</div>-->
                        <div class="flex-column padding-div w-100">
                            <div class="fs-32px fw-700 color-dark">Confirme o e-mail escolhido acima</div>
                            <div class="single-input">
                                <v-text-field placeholder="Digite sua resposta aqui" required :rules="[rules.required, rules.emailconfirm]" @paste="disablePaste" @copy="disableCopy" v-model="emailConfirm" @keyup.native.enter="next" v-on:keyup.enter="next" />
                                <!--:rules="emailRulesConfirmation"-->
                            </div>
                        </div>
                    </div>
                    <div class="d-flex">
                        <!--<div class="question-number color-white">1-</div>-->
                        <div class="flex-column padding-div w-100">
                            <small class="mt-0">
                                <b>*Este e-mail será o login do participante Prevaler no Portal e Aplicativo Valia, ainda que seja menor de idade. Por essa razão, não deve ser o mesmo de outro participante Valia.</b>
                            </small>
                        </div>
                    </div>
                </div>
                <div class="content-buttons">
                    <div class="d-flex justify-content-end">
                        <button v-on:click="next" class="button-primary-bg-primary action-button txt-uppercase">
                            Avançar
                            <v-icon right>east</v-icon>
                        </button>
                    </div>
                    <div class="d-flex justify-content-end display-sm-none">
                        <div class="fs-13px mt-3 fw-400 display-sm-none">ou aperte <span class="fw-700">Enter</span></div>
                    </div>
                </div>
            </v-form>
        </div>

    </transition>
</template>


<script>
    import 'bootstrap';
    import StepTracker from "../../services/StepTracker";

    //import { mapState } from "vuex";
    export default {
        name: 's01-email',
        props: ['pessoa', 'visible', 'fluxo', 'number'],
        data: () => ({
            isActive: false,
            loading: false,
            selection: false,
            step: 1,
            email: "",
            emailConfirm: "",
            hasErrors: false,
            emailRules: [],
            emailRulesConfirmation: [],
            rules: {
            },
            trackMsg: null,
        }),
        watch: {
            visible: function () {
                this.toggle();
            }, 
        },
        methods: {
            disablePaste(event) {
                event.preventDefault();
            },
            disableCopy(event) {
                event.preventDefault();
            },
            toggle() {
                this.isActive = !this.isActive;

                if (this.isActive) {
                    this.habilitaOpcoes();
                }

                this.trackMsg = null;

            },
            habilitaOpcoes() {
                if (StepTracker.returnStep(this.number) != null && this.pessoa !== null && this.pessoa.email != null) {
                    var v = this.pessoa.email
                    this.email = v;
                }
            },
            next: function (event) {
                event.preventDefault(); 

                this.rules = {
                    required: value => !!value || 'Campo obrigatório.',
                    email: value => {
                        if (value == undefined || value == null || value == "" || !value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                            return 'E-mail inválido';
                        }
                    },
                    emailconfirm: value => {
                        if (value == undefined || value == null || value == "" || !value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                            return 'E-mail inválido';
                        }

                        var v = this.email;
                        var v2 = value;
                        if (v != v2) {
                            return 'E-mail e confirmação nao conferem';
                        }
                    },
                };

                this.$nextTick(() => {
                    if (this.$refs.form.validate()) {
                        var v = this.email;
                        this.pessoa.email = v;
                        StepTracker.registerStep(this.number, this.trackMsg);
                        this.$emit('next');
                    }
                })


                
            },
            back: function () {
                this.$emit('back');
            }
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
